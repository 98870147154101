import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1080.000000 825.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g transform="translate(0.000000,825.000000) scale(0.100000,-0.100000)"
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd">
          <path d="M15 4125 c0 -2271 1 -3202 2 -2068 2 1134 2 2992 0 4130 -1 1137 -2
210 -2 -2062z"/>
<path d="M10785 4125 c0 -2271 1 -3202 2 -2068 2 1134 2 2992 0 4130 -1 1137
-2 210 -2 -2062z"/>
<path d="M3855 7634 c38 -11 37 -22 -1 -14 -51 12 -114 2 -107 -16 3 -8 -5
-18 -22 -24 -39 -15 -43 -12 -15 10 31 24 14 26 -20 3 -14 -10 -27 -17 -30
-15 -13 5 -40 -11 -34 -20 5 -7 1 -9 -9 -5 -9 3 -30 -6 -53 -24 -20 -16 -32
-29 -25 -29 6 0 22 10 37 22 14 13 31 23 37 24 34 3 38 5 32 14 -4 6 1 7 11 3
13 -5 15 -3 9 8 -8 12 -6 12 8 1 9 -8 25 -11 38 -7 13 5 25 2 30 -6 4 -7 11
-10 16 -6 4 5 1 12 -7 17 -10 7 -11 10 -2 10 6 0 12 6 12 14 0 16 53 31 71 20
16 -9 119 -13 119 -3 0 4 -8 10 -17 13 -10 2 -7 4 7 3 14 -2 24 -8 23 -14 -2
-7 6 -10 20 -6 18 4 70 10 111 12 5 1 7 3 4 6 -2 3 -63 7 -134 10 -71 2 -120
2 -109 -1z"/>
<path d="M4095 7610 c-11 -5 -37 -9 -57 -9 l-38 -1 0 -75 0 -75 -40 0 -40 0 0
-40 0 -40 -40 0 -40 0 0 -40 0 -40 -40 0 -40 0 0 -40 0 -40 -40 0 -40 0 0 -40
0 -40 -40 0 -40 0 0 -40 0 -40 -40 0 -40 0 0 -40 0 -40 -40 0 -40 0 0 -40 0
-40 -40 0 -40 0 0 -40 0 -40 -40 0 -40 0 0 -40 0 -40 -40 0 -40 0 0 -41 0 -42
-40 6 c-35 4 -40 2 -40 -14 0 -13 -7 -19 -22 -19 -13 0 -41 -3 -62 -7 -31 -5
-37 -3 -31 7 6 10 4 12 -8 7 -9 -3 -17 -2 -19 2 -2 5 -29 10 -61 13 -32 3 -52
1 -45 -3 7 -4 10 -11 6 -14 -6 -6 -106 15 -140 30 -11 4 -18 3 -18 -4 0 -6 -7
-8 -15 -5 -12 5 -13 10 -4 20 6 8 15 11 20 8 5 -3 9 0 9 5 0 18 35 48 51 43
11 -3 11 -2 1 6 -10 7 -9 12 4 23 10 8 15 9 11 2 -4 -6 -4 -14 1 -17 4 -2 8 3
9 13 2 22 63 92 75 85 4 -3 8 -1 8 5 0 14 51 67 61 63 5 -1 12 5 16 14 7 20
52 69 64 70 5 1 14 2 20 3 5 0 7 6 4 12 -4 6 1 17 11 25 18 15 18 15 1 10 -10
-3 -21 -6 -23 -6 -2 0 -4 -10 -5 -22 0 -13 -3 -17 -6 -10 -3 8 -78 -61 -197
-180 -105 -106 -210 -206 -231 -222 -75 -55 -195 -168 -189 -177 3 -5 1 -8 -4
-7 -15 4 -323 -292 -316 -303 3 -5 1 -8 -4 -7 -14 4 -363 -332 -356 -343 3 -5
-3 -6 -12 -2 -13 5 -16 3 -11 -10 4 -10 2 -16 -6 -14 -7 1 -11 -7 -9 -18 2
-12 0 -16 -5 -8 -10 14 -97 -62 -88 -77 4 -6 3 -9 -2 -8 -11 4 -93 -63 -93
-75 0 -4 5 -5 12 -1 7 4 8 3 4 -5 -4 -6 -12 -9 -17 -5 -13 8 -82 -63 -74 -76
3 -5 0 -7 -8 -5 -17 7 -70 -52 -61 -68 4 -7 4 -9 -1 -5 -11 11 -35 -11 -29
-26 3 -7 -2 -19 -11 -26 -9 -7 -15 -8 -15 -2 0 6 6 12 13 15 6 2 9 7 6 11 -8
8 -195 -183 -191 -196 1 -5 -2 -7 -7 -4 -10 7 -97 -93 -93 -106 1 -5 -1 -7 -6
-4 -5 3 -29 -16 -53 -41 -24 -25 -37 -42 -29 -38 8 4 -1 -7 -20 -26 -19 -18
-34 -37 -32 -42 1 -4 -2 -6 -7 -3 -10 7 -41 -31 -41 -49 0 -5 4 -4 9 3 6 11
10 10 15 -4 4 -10 4 -15 0 -11 -4 4 -14 1 -22 -6 -13 -9 -14 -9 -8 1 15 26 -6
10 -49 -35 -25 -27 -43 -54 -40 -61 3 -7 1 -11 -4 -8 -12 8 -44 -31 -36 -44 3
-6 2 -9 -2 -8 -5 2 -30 -23 -56 -54 -39 -48 -44 -58 -31 -69 8 -6 12 -14 9
-17 -2 -3 -11 0 -19 7 -11 9 -19 3 -40 -33 -14 -25 -26 -49 -26 -55 0 -6 -4
-11 -10 -11 -5 0 -10 -10 -10 -21 0 -12 -7 -27 -16 -34 -9 -6 -17 -25 -18 -41
-1 -16 -5 -35 -10 -42 -4 -7 -6 -16 -3 -18 5 -5 16 6 42 41 l15 20 -6 -20
c-12 -43 -28 -65 -43 -63 -19 4 -27 -34 -12 -59 9 -15 10 -12 11 16 0 30 2 33
23 27 12 -3 28 -6 35 -6 7 0 12 -14 12 -35 0 -34 1 -35 40 -35 l40 0 0 -40 0
-40 40 0 40 0 0 -40 0 -40 40 0 40 0 0 -40 0 -40 40 0 40 0 0 -40 0 -40 40 0
40 0 0 -40 0 -40 40 0 40 0 0 -40 0 -40 40 0 40 0 0 -40 0 -40 40 0 40 0 0
-40 0 -40 43 -1 c23 -1 48 -1 55 0 15 2 15 -1 -2 -64 -7 -27 -15 -43 -18 -35
-4 12 -12 -108 -10 -150 1 -8 3 -43 5 -77 4 -68 -3 -90 -23 -78 -8 5 -11 4 -6
-3 3 -6 2 -13 -3 -17 -14 -8 -48 28 -43 46 3 10 1 11 -5 2 -7 -9 -13 -7 -23 8
-25 35 -41 49 -51 44 -5 -4 -9 -1 -9 4 0 6 -11 21 -25 33 -13 11 -22 25 -19
30 5 7 -10 12 -29 9 -5 -1 -6 9 -3 21 3 13 3 17 0 10 -5 -10 -10 -9 -25 4 -10
9 -19 23 -19 31 0 12 -13 17 -33 14 -5 -1 -6 9 -2 21 4 16 3 19 -3 9 -8 -11
-10 -11 -14 0 -3 8 -9 11 -13 7 -10 -10 25 -49 36 -42 5 3 9 1 9 -4 0 -15 62
-82 71 -76 5 3 9 0 9 -6 0 -7 52 -63 115 -126 63 -62 115 -109 115 -104 0 5
-5 12 -10 15 -11 7 -3 54 11 70 5 5 9 -4 9 -22 0 -21 7 -37 22 -48 l23 -17
-22 7 c-41 12 -26 -18 45 -91 37 -38 96 -100 131 -139 35 -38 100 -108 145
-155 393 -412 480 -499 489 -493 6 3 7 1 3 -5 -4 -7 57 -75 146 -166 84 -85
187 -190 230 -233 115 -117 181 -178 190 -176 4 2 7 -1 5 -5 -4 -13 165 -172
176 -165 6 3 7 1 4 -4 -7 -11 220 -229 229 -220 3 2 -3 12 -12 21 -9 9 -14 19
-12 22 7 6 29 -21 32 -39 3 -21 51 -68 72 -71 10 -2 24 -12 29 -23 14 -27 131
-124 140 -115 4 4 5 3 3 -3 -1 -5 0 -10 4 -10 4 0 15 -10 23 -22 9 -13 22 -23
29 -23 8 0 19 -7 27 -16 21 -25 234 -157 234 -145 0 1 19 6 43 12 29 6 49 6
63 -1 25 -14 62 -3 55 16 -5 12 -2 12 15 3 23 -12 143 -8 173 6 14 6 17 5 12
-3 -5 -8 2 -12 21 -13 17 0 2 -7 -42 -19 -38 -11 -74 -20 -79 -20 -19 0 4 13
36 20 l33 8 -37 1 c-20 1 -44 -2 -53 -5 -14 -6 -14 -8 4 -20 14 -10 38 -13 83
-9 58 4 139 22 111 24 -7 1 -22 10 -33 21 l-20 20 20 0 c34 0 22 17 -17 24
-62 10 -73 8 -73 -14 0 -19 -5 -20 -62 -16 -115 8 -169 18 -165 30 2 7 44 12
115 14 91 2 112 5 112 17 0 8 0 18 0 23 0 4 -15 7 -32 7 -31 0 -33 2 -36 38
l-3 37 40 0 41 0 0 40 0 40 40 0 40 0 0 40 0 40 40 0 40 0 0 40 0 40 40 0 40
0 0 40 0 40 40 0 40 0 0 40 0 40 40 0 40 0 0 40 0 40 40 0 40 0 0 40 0 40 40
0 40 0 0 40 0 40 40 0 c39 0 40 1 40 35 0 35 0 35 44 35 l43 0 -3 42 c-2 23
-9 46 -16 51 -9 6 -6 6 7 1 11 -4 38 -9 60 -10 37 -3 37 -2 10 5 l-30 9 32 1
c17 0 34 -3 37 -8 7 -11 89 -25 141 -24 22 1 30 -1 18 -4 -13 -2 -23 -9 -23
-14 0 -12 63 -12 76 1 7 7 25 8 54 1 23 -5 49 -7 57 -4 13 5 13 3 2 -15 -7
-11 -15 -20 -18 -20 -14 3 -41 -15 -41 -27 0 -6 -9 -20 -19 -29 -15 -13 -20
-14 -25 -4 -3 7 -3 3 0 -10 3 -12 2 -22 -3 -21 -4 1 -15 1 -22 0 -12 -1 -13
-3 -2 -13 10 -11 20 -4 52 30 21 24 39 48 39 53 0 6 4 8 8 5 5 -3 24 11 42 32
18 20 43 44 56 52 31 21 128 104 184 158 25 24 112 108 195 185 82 78 213 204
290 279 77 76 150 145 163 154 12 8 19 20 16 26 -4 6 -3 8 3 5 13 -8 86 67 76
78 -5 4 -3 5 3 2 14 -8 167 146 157 158 -4 4 -3 5 3 2 14 -8 167 146 157 158
-4 4 -3 5 3 2 12 -7 101 81 97 95 -2 4 3 7 10 5 6 -1 11 5 9 13 -2 8 0 12 4 8
9 -10 36 11 29 22 -3 5 0 12 6 16 8 5 9 3 4 -7 -8 -12 -6 -12 8 -1 9 8 15 17
14 20 -1 4 0 7 3 7 16 0 37 31 28 42 -7 8 -3 10 14 6 13 -3 20 -2 16 2 -4 4 4
16 17 27 14 11 22 24 19 30 -4 6 -2 8 3 5 6 -4 35 21 64 56 30 34 68 76 85 92
25 24 31 36 27 58 -3 15 -1 27 5 27 6 0 8 -10 5 -22 -5 -22 -4 -22 22 4 23 21
26 30 17 41 -9 11 -7 18 10 33 19 17 21 17 15 2 -4 -10 -2 -18 3 -18 12 0 56
60 53 71 -2 5 0 8 5 6 4 -1 24 16 43 38 26 29 36 49 36 72 0 18 -4 30 -9 27
-4 -3 -5 2 -2 11 6 14 8 14 20 -2 16 -21 18 -37 4 -28 -5 3 -10 1 -10 -4 0
-16 16 -13 30 4 7 8 8 15 3 15 -5 0 -10 6 -10 13 0 8 6 14 13 14 7 0 10 -5 7
-11 -4 -6 0 -5 9 2 10 8 14 25 12 49 -1 21 1 32 5 24 6 -10 10 -9 15 5 3 9 9
14 12 11 3 -3 -2 -18 -11 -34 l-17 -28 26 30 c14 17 26 34 26 39 0 5 7 17 15
27 14 14 14 18 2 31 -13 13 -12 16 4 26 19 12 27 42 11 42 -13 0 -1 73 16 95
l14 20 -7 -20 c-14 -37 -17 -64 -11 -90 10 -41 23 -29 44 43 28 92 31 113 21
142 -7 21 -8 20 -4 -8 3 -18 1 -31 -4 -28 -4 3 -8 -12 -9 -33 -1 -21 -9 -51
-17 -67 l-15 -29 0 28 c0 15 5 27 10 27 24 0 7 31 -20 37 -26 5 -30 10 -30 39
0 33 -1 34 -40 34 l-40 0 0 40 0 40 -40 0 -40 0 0 40 0 40 -40 0 -40 0 0 40 0
40 -40 0 -40 0 0 40 0 40 -40 0 -40 0 0 40 0 40 -40 0 -40 0 0 40 0 40 -40 0
-40 0 0 40 0 40 -40 0 -40 0 0 40 0 40 -40 0 -40 0 0 40 0 40 -40 0 -40 0 0
40 0 40 -41 0 -42 0 6 68 c3 37 2 90 -2 117 l-7 50 -8 -77 c-7 -67 -11 -78
-27 -78 -17 0 -19 8 -19 63 1 58 2 61 16 42 15 -19 16 -17 11 28 -6 64 1 91
19 76 12 -9 14 -4 13 32 -1 24 -4 34 -6 22 -2 -13 -9 -23 -15 -23 -6 0 -8 12
-4 33 4 17 9 60 13 94 3 35 9 61 13 58 4 -3 10 1 13 9 3 7 -11 31 -30 52 -162
178 -267 283 -276 277 -6 -3 -7 -1 -3 5 9 14 -263 294 -277 285 -5 -3 -7 -2
-4 4 8 13 -47 66 -58 56 -4 -5 -5 -3 -1 4 8 15 -132 165 -147 156 -5 -3 -7 -2
-4 4 8 12 -157 176 -167 166 -4 -4 -6 -3 -4 2 4 15 -41 70 -50 60 -4 -4 -1
-10 6 -12 6 -3 12 -9 12 -15 0 -6 -6 -5 -15 2 -9 7 -14 19 -11 26 7 18 -17 36
-32 24 -9 -7 -9 -7 -2 2 6 7 10 16 10 21 0 4 -4 5 -10 2 -5 -3 -10 -1 -10 6 0
16 -81 90 -91 83 -4 -2 -11 1 -15 7 -5 8 -2 10 7 5 8 -4 -64 69 -159 164 -102
101 -177 168 -184 164 -6 -4 -8 -3 -5 3 4 6 -1 17 -10 24 -14 12 -16 12 -8 0
5 -10 4 -12 -3 -7 -7 4 -12 13 -12 21 0 21 -19 28 -38 14 -14 -11 -15 -11 -5
0 9 11 1 25 -44 70 -30 31 -56 54 -57 51 0 -3 -25 19 -55 47 -30 29 -59 49
-64 46 -6 -3 -7 -1 -3 5 6 11 -30 42 -49 42 -5 0 -3 -5 5 -11 13 -9 13 -10 0
-6 -8 2 -15 11 -15 19 0 21 -101 101 -116 92 -7 -4 -9 -3 -6 3 4 6 0 16 -8 23
-13 11 -18 8 -17 -10 0 -3 -15 -6 -34 -6 -19 0 -34 4 -34 8 0 5 10 7 23 3 20
-5 20 -5 4 6 -11 6 -26 12 -35 13 -27 4 -26 26 1 24 14 -1 25 -7 24 -13 -1 -5
6 -9 15 -9 10 1 15 -2 12 -7 -3 -5 0 -9 5 -9 20 0 11 18 -17 37 -56 37 -68 44
-127 73 -72 35 -103 42 -130 30z m185 -67 c3 -3 -5 -2 -18 2 -15 4 -22 3 -20
-5 2 -7 -4 -15 -12 -17 -13 -4 -13 -3 0 6 13 10 13 11 0 11 -13 0 -13 1 0 11
12 8 11 9 -5 6 -11 -2 -19 -9 -17 -15 3 -17 -15 -15 -22 3 -3 10 1 15 12 16
16 1 16 2 0 6 -10 2 -18 8 -18 13 0 9 88 -24 100 -37z m170 -127 c0 -2 -7 -7
-16 -10 -8 -3 -12 -2 -9 4 6 10 25 14 25 6z m22 -24 c-7 -2 -10 -7 -7 -12 3
-4 -10 -7 -27 -6 -18 1 -33 8 -36 16 -2 11 -1 12 5 3 10 -14 53 -6 53 9 0 5 5
6 12 2 9 -6 9 -9 0 -12z m44 -63 c19 -22 19 -22 -3 -10 -13 6 -23 16 -23 21 0
15 5 12 26 -11z m-11 -38 c-6 -5 -25 10 -25 20 0 5 6 4 14 -3 8 -7 12 -15 11
-17z m72 15 c0 -8 -8 -11 -21 -8 -19 5 -19 6 0 13 10 4 19 8 20 8 0 1 1 -5 1
-13z m93 -71 c7 -8 11 -15 9 -16 -37 -7 -40 -7 -29 6 8 10 7 14 -2 18 -8 3 -8
6 -2 6 6 1 17 -6 24 -14z m89 -85 c15 -22 15 -23 -5 -12 -12 6 -23 9 -26 7 -2
-2 -4 5 -5 16 -2 25 15 20 36 -11z m161 -134 c0 -2 -7 -7 -16 -10 -8 -3 -12
-2 -9 4 6 10 25 14 25 6z m58 -71 c-3 -3 -9 2 -12 12 -6 14 -5 15 5 6 7 -7 10
-15 7 -18z m37 -45 c3 -6 -1 -7 -9 -4 -18 7 -21 14 -7 14 6 0 13 -4 16 -10z
m179 -156 c3 -8 2 -12 -4 -9 -6 3 -10 10 -10 16 0 14 7 11 14 -7z m-2234 -124
c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z
m157 -26 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m320 -30 c-3
-3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m103 -78 c0 -3 -4 -8 -10
-11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z m608 -571 c7 3 10 0
7 -6 -9 -14 62 -82 77 -73 7 4 8 3 4 -5 -8 -13 140 -158 156 -153 5 1 7 -1 4
-6 -6 -10 323 -338 336 -335 4 2 7 -1 5 -6 -4 -14 83 -92 95 -85 7 5 8 3 3 -6
-5 -8 -2 -18 9 -25 9 -7 231 -224 492 -481 262 -258 480 -470 485 -472 20 -8
5 -31 -72 -109 -50 -51 -78 -87 -73 -94 4 -8 3 -9 -5 -5 -15 9 -44 -20 -35
-35 4 -8 3 -9 -5 -5 -8 5 -32 -12 -64 -46 -29 -29 -135 -138 -235 -241 l-184
-188 -95 93 c-53 51 -108 105 -123 120 -18 18 -34 26 -49 23 -12 -2 -19 0 -16
6 4 5 11 6 17 3 6 -4 8 -1 6 7 -3 8 -11 13 -17 11 -6 -1 -10 2 -9 7 4 13 -128
131 -137 123 -5 -4 -5 -2 -2 4 8 14 -318 330 -335 326 -7 -2 -9 0 -5 4 10 10
-114 135 -126 127 -6 -3 -7 -2 -4 4 3 5 -7 21 -23 36 -23 22 -30 24 -39 13 -9
-11 -9 -15 1 -20 7 -3 4 -4 -7 -1 -15 4 -37 -13 -86 -63 -164 -170 -237 -362
-227 -596 4 -83 10 -114 35 -172 50 -119 174 -293 352 -494 42 -47 77 -87 79
-90 2 -2 8 2 13 10 9 12 10 12 5 -3 -4 -14 11 -38 54 -85 33 -37 59 -72 58
-78 -5 -19 -550 -574 -564 -574 -7 0 -18 8 -23 18 -10 16 -12 16 -26 -2 -8
-11 -15 -15 -15 -9 0 7 5 15 10 18 6 3 10 11 10 16 0 6 -4 7 -10 4 -5 -3 -10
-1 -10 5 0 6 -5 8 -12 4 -6 -4 -9 -3 -5 2 7 12 -8 31 -51 67 -19 15 -31 27
-27 27 3 0 -3 9 -14 21 -11 13 -24 18 -32 14 -7 -5 -10 -4 -6 2 8 13 -50 66
-63 58 -8 -5 -7 -11 1 -21 19 -23 4 -16 -17 8 -10 12 -14 18 -7 15 6 -4 15 -2
18 3 9 14 -105 131 -119 123 -6 -3 -8 -2 -3 2 10 12 -63 86 -77 78 -6 -3 -8
-2 -3 2 10 12 -63 86 -77 78 -6 -3 -8 -2 -3 2 9 11 -23 46 -35 39 -4 -3 -7 0
-6 8 2 7 -3 12 -10 10 -6 -1 -11 4 -10 10 2 7 -3 12 -10 10 -8 -1 -11 1 -8 6
3 5 -7 19 -22 32 -15 12 -142 136 -282 274 -140 138 -409 402 -597 586 -189
184 -343 339 -343 345 0 6 16 27 36 47 19 21 33 41 30 47 -3 5 2 8 12 7 12 -1
16 6 14 24 -1 19 0 21 7 10 8 -12 21 -3 68 45 32 33 58 67 58 75 0 8 5 15 12
15 27 0 398 379 387 396 -5 8 -3 9 6 4 8 -5 18 -1 26 10 7 9 17 15 23 14 5 -2
75 -67 154 -144 79 -77 247 -240 373 -362 126 -122 270 -262 319 -312 50 -50
94 -91 98 -91 10 0 151 142 189 190 42 54 84 137 113 225 35 109 45 281 20
375 -41 159 -186 360 -530 737 -27 31 -53 60 -56 67 -4 6 114 134 278 301
l286 290 96 -98 c56 -57 101 -95 109 -92z m-73 155 c0 -7 -7 -15 -15 -17 -13
-4 -13 -3 0 6 13 10 13 12 0 22 -13 9 -13 10 0 6 8 -2 15 -10 15 -17z m60
-120 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z
m86 -87 c13 -16 12 -17 -3 -4 -10 7 -18 15 -18 17 0 8 8 3 21 -13z m-2861
-526 c0 -2 -12 -14 -27 -28 l-28 -24 24 28 c23 25 31 32 31 24z m-149 -120
c-10 -9 -11 -8 -5 6 3 10 9 15 12 12 3 -3 0 -11 -7 -18z m-25 -60 c0 -15 -8
-22 -22 -23 l-21 -2 21 23 c12 13 22 23 22 24 1 1 1 -9 0 -22z m-103 -90 c3
-7 -3 -13 -14 -13 -13 0 -15 3 -7 13 6 7 12 13 13 13 2 0 5 -6 8 -13z m-44
-60 c0 -9 -2 -8 -6 1 -2 6 -10 9 -15 6 -7 -4 -8 -2 -4 5 9 13 26 5 25 -12z
m-99 -102 c-14 -17 -30 -20 -30 -4 0 5 4 8 9 4 5 -3 12 -1 16 5 3 5 9 10 12
10 3 0 0 -7 -7 -15z m-147 -166 c-17 -17 -37 -1 -22 17 8 10 15 12 23 4 7 -7
7 -13 -1 -21z m6 -64 c0 -5 -4 -3 -9 5 -5 8 -9 22 -9 30 0 16 17 -16 18 -35z
m-39 25 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z
m-58 -67 c-6 -10 -20 -27 -33 -38 -19 -17 -18 -14 6 23 27 41 47 53 27 15z m8
-32 c-5 -11 -15 -23 -21 -27 -6 -3 -3 5 6 20 20 30 30 35 15 7z m-105 -81 c-3
-11 -2 -20 3 -20 6 0 12 5 14 12 3 8 6 7 10 -4 5 -11 0 -15 -15 -14 -12 1 -22
7 -21 14 2 25 4 32 10 32 3 0 2 -9 -1 -20z m-45 -140 c0 -13 -1 -13 -10 0 -8
12 -10 11 -10 -6 0 -12 -7 -25 -16 -28 -17 -7 -17 -3 8 46 13 27 28 20 28 -12z
m-3 -67 c-3 -10 -5 -4 -5 12 0 17 2 24 5 18 2 -7 2 -21 0 -30z m1459 -108 c-9
-9 -16 -13 -16 -8 0 12 22 34 28 28 2 -2 -3 -11 -12 -20z m24 -14 c0 -5 -7
-12 -16 -15 -14 -5 -15 -4 -4 9 14 17 20 19 20 6z m3540 -235 c0 -2 -8 -10
-17 -17 -16 -13 -17 -12 -4 4 13 16 21 21 21 13z m1639 -83 c0 -25 -1 -26 -9
-8 -12 27 -12 35 0 35 6 0 10 -12 9 -27z m34 7 c-3 -12 -8 -19 -11 -16 -5 6 5
36 12 36 2 0 2 -9 -1 -20z m-143 -201 c0 -10 -27 -19 -34 -12 -11 12 -6 19 14
19 11 0 20 -3 20 -7z m-275 -319 c-3 -5 -12 -10 -18 -10 -7 0 -6 4 3 10 19 12
23 12 15 0z m-5575 -270 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10
10 6 0 10 -4 10 -10z m75 -80 c16 -17 16 -20 3 -15 -9 4 -19 13 -22 21 -7 19
-2 18 19 -6z m2376 -563 c-6 -8 -7 -18 -3 -22 4 -5 1 -5 -6 -1 -10 6 -10 11
-1 22 6 8 14 14 16 14 3 0 0 -6 -6 -13z m94 -105 c-3 -3 -14 -6 -25 -7 -15 -1
-17 2 -10 15 5 10 6 21 2 26 -4 5 3 0 16 -10 12 -11 20 -22 17 -24z m110 -82
c-3 -5 -12 -10 -18 -10 -7 0 -6 4 3 10 19 12 23 12 15 0z m562 -406 c-3 -3
-12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m187 -65 c-8 -14 -24 -10 -24
6 0 9 6 12 15 9 8 -4 12 -10 9 -15z m-1793 -352 c-10 -9 -11 -8 -5 6 3 10 9
15 12 12 3 -3 0 -11 -7 -18z m49 -7 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3
6 -1 10 4 10 6 0 11 -4 11 -10z m21 -67 c13 -16 12 -17 -3 -4 -17 13 -22 21
-14 21 2 0 10 -8 17 -17z m174 -114 c11 -16 1 -19 -13 -3 -7 8 -8 14 -3 14 5
0 13 -5 16 -11z m80 -68 c-3 -6 -11 -11 -16 -11 -5 0 -4 6 3 14 14 16 24 13
13 -3z m59 -66 c11 -8 16 -15 10 -15 -5 0 -18 7 -28 15 -11 8 -16 15 -10 15 5
0 18 -7 28 -15z m22 -45 c0 -12 -4 -16 -9 -11 -5 5 -6 14 -3 20 10 15 12 14
12 -9z m45 -37 c13 -16 12 -17 -3 -4 -17 13 -22 21 -14 21 2 0 10 -8 17 -17z
m176 -95 c7 -6 13 -15 13 -20 0 -6 -7 -5 -15 2 -9 7 -19 9 -23 5 -4 -4 -1 -10
6 -12 6 -3 12 -9 12 -15 0 -17 -55 33 -56 52 -1 16 3 17 25 9 14 -6 31 -15 38
-21z m17 -49 c-4 -8 1 -9 17 -4 18 5 20 4 9 -4 -23 -16 13 -25 40 -11 15 8 20
8 16 1 -4 -6 -1 -11 8 -11 20 0 31 -19 18 -32 -6 -6 -15 -2 -26 13 -8 13 -19
20 -23 16 -3 -4 2 -14 13 -22 37 -28 4 -15 -40 17 -38 26 -51 48 -30 48 3 0 2
-5 -2 -11z m166 -9 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6
0 10 -4 10 -10z m35 -81 c16 -8 15 -8 -8 -4 -16 3 -26 2 -23 -3 3 -5 -1 -9 -9
-9 -11 0 -12 4 -4 14 13 15 17 15 44 2z m98 -6 c-7 -2 -19 -2 -25 0 -7 3 -2 5
12 5 14 0 19 -2 13 -5z m260 -30 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19
-2 13 -5z"/>
<path d="M775 4750 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M3440 940 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"/>
<path d="M3655 7547 c-12 -6 -13 -13 -5 -28 13 -24 33 -15 25 11 -5 17 -3 18
27 9 41 -11 51 -11 44 0 -8 13 -73 19 -91 8z"/>
<path d="M3842 7535 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"/>
<path d="M3807 7543 c-13 -12 -7 -33 8 -33 8 0 15 9 15 20 0 20 -11 26 -23 13z"/>
<path d="M3576 7493 c-4 -9 0 -23 9 -32 9 -9 15 -11 15 -4 0 7 -5 15 -10 18
-8 5 -7 11 1 21 7 8 7 14 1 14 -5 0 -13 -8 -16 -17z"/>
<path d="M3397 7381 c-83 -84 -115 -124 -111 -135 3 -9 11 -16 16 -16 7 0 6 5
-1 14 -9 11 -8 15 4 20 9 3 13 11 9 17 -4 8 0 9 15 4 11 -3 21 -2 21 3 0 5 12
14 26 20 20 10 28 9 40 -3 15 -15 24 -19 24 -11 0 3 -7 11 -15 20 -12 11 -13
21 -5 42 9 24 9 25 -5 14 -8 -7 -15 -18 -15 -24 0 -6 -11 -16 -25 -23 -14 -6
-25 -9 -25 -7 0 13 85 92 93 87 6 -3 7 -1 3 5 -4 7 -1 12 9 12 8 0 20 12 25
26 7 17 15 24 25 20 17 -6 26 5 16 22 -5 8 -47 -28 -124 -107z"/>
<path d="M3747 7483 c-12 -11 -8 -21 11 -26 9 -2 5 -4 -10 -2 -15 1 -25 -2
-22 -7 6 -9 48 -11 56 -2 3 3 4 14 1 25 -5 19 -23 25 -36 12z"/>
<path d="M3538 7453 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M3504 7421 c10 -15 -11 -41 -34 -41 -11 0 -17 -7 -16 -17 0 -10 -5
-17 -11 -15 -7 1 -13 -2 -13 -8 0 -11 11 -13 34 -4 9 3 16 12 16 19 0 7 9 15
20 18 22 6 28 41 8 53 -7 4 -8 3 -4 -5z"/>
<path d="M3497 7306 c-3 -8 2 -16 12 -19 14 -3 17 0 14 14 -6 22 -19 25 -26 5z"/>
<path d="M3350 7256 c0 -16 -2 -17 -17 -5 -15 12 -16 12 -10 -4 5 -13 -2 -26
-23 -44 -18 -14 -30 -34 -29 -46 1 -17 2 -18 6 -4 2 9 11 17 19 17 8 0 17 8
21 18 11 30 25 44 33 32 5 -8 9 -4 12 8 3 11 2 26 -3 33 -7 11 -9 10 -9 -5z"/>
<path d="M3250 7231 c-33 -35 -43 -51 -30 -51 6 0 14 9 17 20 3 11 10 20 15
20 5 0 6 -4 3 -10 -3 -5 -2 -10 3 -10 13 0 22 24 14 37 -5 6 -13 4 -22 -6z"/>
<path d="M3240 7149 c0 -16 4 -28 9 -25 8 5 3 56 -5 56 -2 0 -4 -14 -4 -31z"/>
<path d="M3165 7148 c-17 -16 -17 -18 -2 -12 13 5 16 2 11 -10 -8 -22 3 -20
15 2 15 28 -1 42 -24 20z"/>
<path d="M3337 7153 c-3 -3 -3 -11 0 -19 7 -18 33 -18 33 0 0 14 -24 29 -33
19z"/>
<path d="M3103 7087 c-31 -29 -33 -34 -15 -32 12 1 25 5 29 10 5 4 2 5 -6 0
-9 -5 -12 -3 -9 6 3 8 14 19 26 25 29 14 38 24 24 24 -7 0 -29 -15 -49 -33z"/>
<path d="M3197 7114 c-4 -4 -7 -19 -6 -33 l0 -26 10 24 c10 26 8 47 -4 35z"/>
<path d="M3161 7078 c-1 -20 -5 -26 -16 -22 -9 4 -15 0 -15 -10 0 -9 -4 -16
-10 -16 -5 0 -10 -12 -10 -27 0 -16 4 -23 8 -17 4 7 16 13 26 15 23 5 35 44
25 79 l-7 25 -1 -27z"/>
<path d="M3080 6995 c0 -14 4 -25 9 -25 4 0 6 11 3 25 -2 14 -6 25 -8 25 -2 0
-4 -11 -4 -25z"/>
<path d="M3045 6959 c-4 -11 -4 -23 -1 -26 3 -4 6 1 6 10 0 10 5 17 10 17 6 0
10 5 10 10 0 18 -19 10 -25 -11z"/>
<path d="M3010 6955 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"/>
<path d="M2970 6879 c-8 -11 -14 -24 -13 -29 4 -14 -26 -32 -52 -32 -13 0 -22
-4 -20 -9 3 -4 1 -10 -5 -14 -5 -3 -10 -1 -10 4 0 6 -4 11 -9 11 -5 0 -7 -9
-4 -20 6 -22 33 -28 33 -7 0 7 16 19 35 26 19 7 35 17 35 22 0 5 11 9 25 9 21
0 25 5 25 30 0 35 -19 39 -40 9z"/>
<path d="M2910 6839 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M2830 6760 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M2828 6730 c-37 -20 -45 -29 -36 -38 7 -7 22 -12 35 -12 18 0 23 6
23 25 0 13 8 28 17 34 10 5 16 11 13 13 -3 3 -26 -7 -52 -22z"/>
<path d="M2750 6680 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M3048 6643 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M6414 5458 l-19 -23 23 19 c21 18 27 26 19 26 -2 0 -12 -10 -23 -22z"/>
<path d="M6432 5428 c-14 -14 -16 -58 -3 -58 5 0 8 8 7 17 -2 27 41 29 49 3 5
-17 9 -19 21 -9 13 10 12 14 -2 30 -10 11 -19 17 -22 14 -3 -2 -12 0 -22 5
-11 6 -21 5 -28 -2z m68 -23 c7 -9 8 -15 2 -15 -5 0 -12 7 -16 15 -3 8 -4 15
-2 15 2 0 9 -7 16 -15z"/>
<path d="M6515 5371 c-3 -6 2 -16 10 -23 9 -7 22 -19 30 -26 14 -12 17 -9 14
16 0 4 -7 6 -15 4 -8 -2 -14 5 -14 18 0 21 -14 27 -25 11z"/>
<path d="M6483 5315 c4 -9 7 -19 7 -21 0 -2 7 -4 15 -4 22 0 18 18 -7 31 -18
10 -20 9 -15 -6z"/>
<path d="M6583 5309 c-15 -15 12 -59 35 -59 13 0 13 3 -2 19 -13 15 -14 20 -4
25 7 3 2 3 -12 -1 -22 -5 -23 -4 -11 11 15 18 11 22 -6 5z"/>
<path d="M6655 5251 c19 -23 19 -24 1 -17 -17 6 -18 5 -4 -16 19 -28 34 -33
42 -13 5 13 1 15 -16 9 -20 -6 -21 -5 -10 8 10 12 8 18 -11 34 l-22 19 20 -24z"/>
<path d="M6560 5230 c0 -5 4 -10 9 -10 6 0 13 5 16 10 3 6 -1 10 -9 10 -9 0
-16 -4 -16 -10z"/>
<path d="M6712 5177 c-9 -10 -7 -17 8 -29 15 -13 21 -14 29 -4 8 10 6 17 -8
30 -15 14 -20 14 -29 3z m34 -24 c-7 -7 -26 7 -26 19 0 6 6 6 15 -2 9 -7 13
-15 11 -17z"/>
<path d="M6653 5133 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M6805 5088 c4 -36 4 -38 14 -38 18 0 19 29 2 44 -17 16 -18 15 -16
-6z m20 -8 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11
-10z"/>
<path d="M6889 5023 c0 -5 -2 -17 -3 -29 -2 -15 3 -21 17 -20 11 1 22 7 23 14
1 7 0 8 -3 2 -8 -16 -33 8 -26 26 3 8 2 14 -1 14 -3 0 -6 -3 -7 -7z"/>
<path d="M6800 5010 c0 -15 28 -42 40 -38 5 2 -2 14 -15 27 -14 13 -25 18 -25
11z"/>
<path d="M6900 4941 c0 -11 29 -35 35 -29 10 9 -7 38 -21 38 -8 0 -14 -4 -14
-9z"/>
<path d="M6969 4943 c0 -5 -2 -18 -3 -29 -2 -17 2 -21 21 -19 13 1 34 -8 48
-21 26 -24 34 -41 13 -28 -7 4 -8 3 -4 -5 4 -6 2 -18 -4 -27 -10 -12 -8 -19
14 -39 14 -13 26 -28 26 -33 0 -5 7 -9 15 -9 24 0 17 36 -9 49 -32 14 -43 39
-16 35 27 -4 57 -38 55 -63 -1 -16 4 -20 22 -18 12 1 25 -2 29 -7 3 -5 10 -9
15 -9 6 0 -34 45 -88 100 -54 54 -100 95 -101 89 -3 -6 -9 -6 -17 1 -8 6 -11
18 -8 26 3 8 2 14 -1 14 -3 0 -6 -3 -7 -7z m182 -190 c13 -16 12 -17 -3 -4
-17 13 -22 21 -14 21 2 0 10 -8 17 -17z"/>
<path d="M7213 4693 c29 -34 87 -125 87 -139 0 -8 5 -14 10 -14 6 0 10 -9 10
-20 0 -23 29 -85 36 -77 4 4 -12 48 -43 117 -20 44 -95 149 -107 150 -5 0 -2
-8 7 -17z"/>
<path d="M7197 4665 c-4 -9 -3 -13 2 -10 6 3 14 -3 20 -12 5 -10 13 -14 17
-10 4 4 -1 17 -13 28 -18 18 -21 19 -26 4z"/>
<path d="M7203 4612 c2 -32 11 -41 22 -22 3 5 -1 20 -9 32 -15 21 -15 21 -13
-10z"/>
<path d="M7245 4600 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M7239 4528 c2 -38 6 -47 12 -35 6 9 11 30 12 47 1 20 6 29 15 25 7
-3 10 0 7 5 -3 6 -16 10 -28 10 -20 0 -22 -4 -18 -52z"/>
<path d="M7287 4490 c4 -48 0 -56 -29 -51 -12 2 -9 -9 7 -25 15 -16 55 -11 55
7 0 5 9 1 20 -8 11 -10 20 -27 20 -40 0 -12 5 -25 12 -29 8 -5 9 0 5 17 -4 13
-7 31 -7 40 0 9 -9 19 -20 22 -11 3 -20 13 -20 24 0 10 -5 25 -11 33 -8 12 -9
9 -5 -13 4 -18 1 -33 -8 -42 -19 -19 -32 -20 -17 -1 6 7 11 27 9 43 -1 16 1
34 5 40 4 6 1 16 -7 22 -12 10 -13 3 -9 -39z"/>
<path d="M7366 4295 c8 -50 14 -58 14 -19 0 16 -5 36 -11 44 -7 11 -8 4 -3
-25z"/>
<path d="M7371 4214 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M464 4108 c4 -35 8 -75 8 -89 1 -21 5 -25 20 -21 11 2 15 8 10 11
-16 11 -34 81 -30 122 2 21 -1 39 -5 39 -5 0 -6 -27 -3 -62z"/>
<path d="M520 3980 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M480 3967 c0 -10 5 -17 11 -17 6 0 9 -9 6 -19 -3 -14 -1 -18 9 -14
20 7 17 27 -6 48 -18 16 -20 16 -20 2z"/>
<path d="M550 3945 c-2 -11 -2 -28 -1 -39 1 -16 -1 -17 -16 -5 -15 12 -16 12
-10 -4 4 -10 7 -21 7 -23 0 -14 18 0 33 26 10 17 14 30 8 30 -5 0 -12 8 -14
18 -4 15 -5 14 -7 -3z"/>
<path d="M7293 3935 c-7 -8 -9 -16 -4 -19 5 -3 12 1 15 10 8 22 4 25 -11 9z"/>
<path d="M622 3913 c4 -32 21 -34 22 -2 1 15 -4 26 -12 26 -8 0 -12 -9 -10
-24z"/>
<path d="M520 3855 c0 -9 29 -75 33 -75 1 0 3 17 4 38 0 28 -1 32 -7 17 -7
-16 -9 -17 -9 -3 -1 15 -21 38 -21 23z"/>
<path d="M585 3850 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0 -7
-4 -4 -10z"/>
<path d="M612 3770 c-6 -10 -9 -27 -5 -37 6 -16 5 -17 -13 -3 -18 14 -18 14
-5 -2 7 -10 18 -15 24 -11 6 4 8 0 4 -9 -3 -9 2 -23 11 -31 15 -14 20 -14 42
-1 23 13 23 14 3 9 -18 -5 -27 1 -43 27 -21 35 -26 53 -11 43 13 -7 25 15 14
26 -5 5 -14 0 -21 -11z m29 -87 c13 -16 12 -17 -3 -4 -10 7 -18 15 -18 17 0 8
8 3 21 -13z"/>
<path d="M777 3764 c8 -21 29 -23 29 -4 0 11 -7 18 -17 17 -9 -1 -15 -7 -12
-13z"/>
<path d="M566 3758 c3 -5 10 -6 15 -3 13 9 11 12 -6 12 -8 0 -12 -4 -9 -9z"/>
<path d="M650 3730 c-15 -9 -8 -24 13 -26 7 0 20 -2 31 -2 11 -1 16 -7 13 -16
-4 -10 -1 -13 7 -10 16 6 29 -22 21 -47 -5 -14 0 -20 20 -25 14 -3 29 -4 32 0
4 3 -1 6 -11 6 -13 0 -16 5 -11 20 10 30 -19 75 -62 98 -26 14 -33 15 -53 2z"/>
<path d="M697 3649 c-9 -5 -23 -7 -30 -3 -6 4 23 -28 65 -72 57 -57 81 -75 89
-67 8 8 5 13 -10 18 -12 3 -18 11 -15 17 4 6 2 8 -3 5 -6 -4 -17 1 -24 10 -12
15 -12 16 5 10 16 -6 17 -5 7 7 -7 8 -20 12 -28 9 -11 -4 -14 -2 -9 5 7 11 -6
17 -28 13 -5 -1 -6 5 -3 13 4 10 1 13 -9 9 -20 -7 -18 7 4 23 20 16 14 18 -11
3z"/>
<path d="M936 3606 c8 -22 36 -30 32 -9 -2 10 -11 19 -21 21 -12 2 -15 -1 -11
-12z"/>
<path d="M7446 3593 c-25 -120 -75 -456 -70 -465 4 -5 2 -13 -3 -17 -12 -8
-66 -338 -57 -352 4 -6 2 -14 -5 -18 -6 -4 -12 -31 -13 -60 -1 -29 -6 -55 -10
-57 -11 -7 -10 -24 1 -24 8 0 32 57 45 105 3 11 2 0 -1 -25 -4 -25 -7 -60 -7
-77 -1 -35 -6 -39 -23 -22 -17 17 -28 -34 -14 -58 8 -14 9 -11 4 16 -5 26 -3
33 8 29 8 -2 15 -21 17 -41 2 -21 9 -37 15 -37 9 0 11 18 6 68 -6 69 -3 92 12
92 5 0 6 -12 3 -28 -5 -27 -5 -27 40 -24 37 3 46 1 46 -12 0 -12 10 -16 40
-16 33 0 40 -4 40 -19 0 -16 11 -20 80 -26 70 -6 80 -10 80 -26 0 -18 -10 -19
-205 -19 -147 1 -204 4 -199 12 4 7 2 8 -5 4 -36 -23 12 -27 281 -26 253 0
287 -1 282 -15 -5 -12 -43 -15 -248 -18 l-241 -2 272 -3 c184 -1 274 1 278 9
11 17 -3 23 -20 9 -13 -11 -14 -10 -9 4 3 9 10 16 15 16 5 0 9 4 9 8 0 5 -7 9
-15 9 -8 0 -15 -7 -15 -15 0 -9 -2 -13 -5 -10 -4 3 -3 18 1 34 7 26 8 26 16 9
6 -14 7 -8 5 22 -1 23 1 39 5 37 5 -3 8 -20 8 -39 0 -19 6 -37 13 -41 6 -4 9
-4 6 -1 -3 4 0 38 7 75 22 114 40 103 -168 102 l-183 -1 2 38 c2 34 1 35 -7
14 -6 -13 -13 -22 -15 -19 -8 7 13 58 23 58 4 0 14 41 21 90 l14 90 173 0 c96
0 174 1 174 3 0 1 7 40 15 87 8 47 15 97 15 112 l0 28 -167 2 c-153 3 -166 4
-169 22 -1 10 1 28 6 40 5 11 14 54 20 93 l12 73 186 2 187 3 17 95 c9 52 17
103 17 113 1 16 -21 17 -318 17 l-319 0 -6 -27z m-29 -425 c-3 -8 -6 -5 -6 6
-1 11 2 17 5 13 3 -3 4 -12 1 -19z m150 -340 c-3 -7 -5 -2 -5 12 0 14 2 19 5
13 2 -7 2 -19 0 -25z m-230 0 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1
-19z m210 -35 c-3 -10 -5 -4 -5 12 0 17 2 24 5 18 2 -7 2 -21 0 -30z m20 -15
c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m-216 -24 c-9 -28 -9
-29 -10 -3 -1 14 2 29 6 32 11 12 13 2 4 -29z m189 -34 c0 -5 -2 -10 -4 -10
-3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z m-223 -32 c-3 -8 -6 -5
-6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m40 -20 c-3 -7 -5 -2 -5 12 0 14 2 19
5 13 2 -7 2 -19 0 -25z"/>
<path d="M830 3564 c-8 -8 -9 -14 -4 -13 33 6 82 -41 76 -73 -1 -4 5 -5 13 -2
23 9 18 52 -10 79 -28 29 -57 32 -75 9z"/>
<path d="M6975 3520 c3 -5 11 -10 16 -10 6 0 7 5 4 10 -3 6 -11 10 -16 10 -6
0 -7 -4 -4 -10z"/>
<path d="M834 3497 c-14 -15 42 -78 63 -70 12 5 12 7 2 14 -7 4 -17 5 -22 2
-5 -3 -6 5 -3 18 3 13 3 18 0 11 -8 -18 -35 5 -27 24 6 17 2 17 -13 1z"/>
<path d="M936 3436 c10 -8 21 -12 24 -10 5 6 -21 24 -34 24 -6 0 -1 -6 10 -14z"/>
<path d="M1105 3440 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M980 3405 c-10 -12 -9 -15 4 -16 9 -1 21 -3 26 -4 6 -1 14 -3 19 -4
5 0 14 -15 21 -31 7 -17 18 -29 24 -27 21 7 17 34 -9 66 -29 34 -65 41 -85 16z"/>
<path d="M8372 3317 c-107 -35 -186 -124 -208 -237 l-6 -30 120 0 120 0 5 29
c11 51 48 83 105 91 28 3 49 3 46 -1 -2 -4 3 -9 11 -13 20 -7 20 -89 0 -106
-8 -7 -12 -16 -9 -21 3 -5 2 -9 -3 -8 -4 1 -39 -13 -78 -31 -38 -19 -79 -35
-89 -37 -11 -2 -24 -7 -30 -12 -6 -4 -32 -15 -58 -24 -57 -19 -141 -74 -132
-88 3 -5 0 -8 -7 -7 -26 6 -75 -86 -89 -165 -13 -75 -9 -103 13 -90 8 4 8 1
-1 -9 -23 -28 20 -92 81 -122 51 -26 99 -34 94 -17 -1 5 3 9 8 9 6 0 9 -4 8
-9 -2 -5 4 -9 12 -9 8 0 15 4 15 9 0 5 17 9 37 8 20 -1 38 4 41 11 2 7 10 12
19 12 15 0 75 38 81 52 2 4 10 8 17 8 8 0 12 -13 11 -37 l-1 -38 120 -3 120
-3 1 26 c1 14 -2 25 -8 25 -5 0 -7 4 -4 8 2 4 10 46 16 92 6 47 27 180 46 295
51 309 49 348 -24 412 -65 56 -273 72 -400 30z m-135 -477 c-2 -23 -10 -17
-12 10 -1 10 2 17 6 14 4 -2 7 -13 6 -24z m303 -2 c-14 -35 -30 -146 -24 -166
5 -14 4 -15 -5 -2 -8 11 -11 11 -12 3 -1 -7 -2 -18 -3 -23 0 -6 -7 -15 -15
-19 -7 -5 -11 -14 -7 -20 5 -7 1 -8 -10 -4 -11 4 -15 2 -11 -4 7 -11 -74 -17
-104 -8 -37 12 -42 120 -7 162 7 9 7 18 -3 31 -11 14 -11 15 0 5 11 -9 20 -9
43 3 16 8 26 19 23 24 -4 6 1 7 11 3 12 -4 15 -3 10 5 -4 8 -2 10 7 6 8 -3 30
2 48 10 53 23 70 21 59 -6z m-392 -63 c-3 -3 -9 2 -12 12 -6 14 -5 15 5 6 7
-7 10 -15 7 -18z m-27 -18 c-10 -9 -11 -8 -5 6 3 10 9 15 12 12 3 -3 0 -11 -7
-18z m616 -159 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m-383
-16 c2 -4 -4 -8 -14 -8 -10 0 -16 4 -14 8 3 4 9 8 14 8 5 0 11 -4 14 -8z
m-204 -77 c0 -8 -4 -15 -10 -15 -5 0 -10 7 -10 15 0 8 5 15 10 15 6 0 10 -7
10 -15z m404 -21 c9 -24 7 -27 -9 -10 -17 17 -19 26 -6 26 5 0 12 -7 15 -16z
m-383 -3 c-11 -7 -9 -10 10 -14 13 -2 7 -3 -14 -2 -21 1 -41 6 -45 10 -4 5 1
5 10 2 10 -3 20 -2 23 3 4 6 12 10 18 10 8 0 7 -4 -2 -9z m229 -5 c-7 -7 -13
-7 -20 0 -6 6 -3 10 10 10 13 0 16 -4 10 -10z m-150 -16 c0 -5 -8 -10 -17 -10
-15 0 -16 2 -3 10 19 12 20 12 20 0z m120 0 c0 -5 -7 -10 -16 -10 -8 0 -12 -4
-9 -10 4 -6 -1 -7 -11 -3 -14 5 -13 8 7 19 29 16 29 16 29 4z m-133 -36 c-3
-3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z"/>
<path d="M9195 3329 c-157 -56 -226 -143 -233 -293 -3 -62 -1 -77 8 -71 9 5
11 4 6 -4 -22 -34 82 -130 209 -194 39 -19 77 -43 87 -53 18 -21 27 -64 16
-79 -5 -5 -16 -21 -26 -33 -13 -17 -31 -25 -64 -29 -40 -5 -50 -2 -72 20 -19
20 -26 36 -26 67 l0 40 -104 0 c-117 0 -116 0 -116 -76 0 -84 47 -156 127
-195 40 -19 64 -23 145 -23 59 0 102 5 111 12 7 7 16 10 18 7 9 -8 114 52 111
64 -1 7 2 9 8 6 12 -7 25 6 66 68 14 22 23 45 20 53 -3 8 -2 13 3 9 9 -5 31
78 31 118 -1 72 -69 168 -145 204 -16 8 -59 31 -95 52 -36 21 -68 37 -71 35
-3 -2 -6 2 -7 9 -1 7 -5 27 -8 45 -12 61 45 110 113 97 25 -4 34 -13 43 -41 7
-19 9 -46 6 -60 l-6 -24 111 0 112 0 -5 79 c-4 67 -9 85 -32 115 -45 60 -96
79 -211 83 -55 1 -113 -2 -130 -8z m-251 -793 c-3 -15 -8 -25 -11 -23 -2 3 -1
17 3 31 3 15 8 25 11 23 2 -3 1 -17 -3 -31z m81 -76 c-27 -12 -43 -12 -25 0 8
5 22 9 30 9 10 0 8 -3 -5 -9z m290 -10 c-3 -5 -14 -10 -23 -10 -15 0 -15 2 -2
10 20 13 33 13 25 0z"/>
<path d="M9677 3313 c-3 -5 6 -214 20 -466 19 -360 22 -465 13 -487 -13 -32
-59 -55 -101 -52 -29 2 -30 0 -44 -88 -3 -19 -7 -47 -9 -62 l-5 -28 90 0 c49
0 97 4 107 9 9 6 29 12 43 15 29 6 75 44 71 58 -1 5 2 7 6 4 20 -12 107 156
177 344 20 52 47 122 60 155 55 137 225 588 225 596 0 5 -53 9 -117 9 l-118 0
-38 -123 c-21 -67 -40 -129 -41 -137 -2 -8 -6 -22 -10 -30 -3 -8 -26 -76 -50
-150 l-44 -135 -1 288 -1 287 -114 0 c-63 0 -117 -3 -119 -7z"/>
<path d="M1083 3275 c2 -10 18 -25 27 -25 3 0 6 8 6 18 0 10 -8 18 -17 18 -10
0 -17 -5 -16 -11z"/>
<path d="M1144 3245 c-10 -8 -14 -14 -9 -13 6 1 24 -3 41 -9 29 -10 52 -49 45
-77 -1 -5 5 -4 13 4 24 19 19 48 -13 81 -33 33 -49 36 -77 14z"/>
<path d="M1163 3195 c0 -8 4 -15 9 -15 4 0 8 4 8 9 0 6 -4 12 -8 15 -5 3 -9
-1 -9 -9z"/>
<path d="M7268 2433 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M8612 1803 c-7 -43 -15 -90 -18 -105 l-6 -28 121 0 c138 0 121 -16
144 128 l14 82 -122 0 -121 0 -12 -77z"/>
<path d="M5757 1863 c-3 -5 -13 -57 -22 -118 -22 -149 -23 -152 -35 -214 -6
-30 -8 -63 -4 -73 3 -10 1 -18 -4 -18 -11 0 -35 -167 -26 -182 5 -7 3 -8 -6
-3 -9 6 -10 0 -5 -24 4 -18 3 -37 -3 -44 -16 -20 -22 -73 -12 -108 5 -20 5
-48 0 -69 l-9 -35 2 43 c1 23 -1 42 -5 42 -8 0 -13 -38 -9 -79 1 -13 -3 -27
-10 -31 -6 -4 -9 -14 -7 -21 3 -9 10 -5 22 13 22 34 38 92 38 138 0 32 1 33 8
10 4 -14 8 -18 9 -11 1 11 4 12 12 4 14 -14 4 -47 -11 -38 -14 9 -12 -2 6 -30
9 -13 13 -29 9 -35 -3 -6 3 -10 14 -10 19 0 21 6 22 58 0 31 3 50 7 42 4 -8
14 -15 22 -15 8 0 17 -14 21 -33 10 -50 26 -60 34 -23 3 17 3 36 0 42 -5 7 -1
10 11 7 11 -2 19 -12 18 -23 -2 -44 -14 -127 -20 -136 -3 -5 -1 -16 4 -25 12
-22 37 136 35 226 0 36 2 57 6 47 4 -11 14 32 24 103 10 66 15 125 12 131 -4
5 -2 15 3 22 5 7 15 55 21 107 12 95 26 137 26 77 0 -18 4 -143 9 -279 6 -190
12 -250 22 -259 8 -6 14 -18 14 -25 0 -8 5 -14 10 -14 6 0 8 -10 5 -22 -2 -13
-1 -39 4 -58 8 -30 9 -32 10 -9 2 44 13 89 22 89 5 0 9 -11 9 -25 0 -25 14
-33 25 -15 3 6 37 10 74 10 63 0 69 -2 80 -26 6 -14 9 -30 6 -35 -4 -5 -2 -17
4 -26 8 -16 11 -14 21 12 45 117 56 152 53 163 -3 6 0 12 7 12 8 0 8 6 -1 23
-9 18 -9 20 1 10 12 -12 25 13 54 102 2 6 10 26 19 45 8 19 17 44 19 55 1 11
6 22 9 25 3 3 27 65 54 138 27 72 52 132 56 132 4 0 3 -15 -1 -32 -5 -18 -12
-49 -14 -68 -3 -19 -12 -69 -21 -110 -8 -41 -13 -81 -10 -87 2 -7 1 -13 -3
-13 -12 0 -44 -194 -36 -215 5 -13 4 -16 -5 -10 -10 5 -11 2 -7 -13 4 -11 2
-23 -3 -26 -11 -7 -27 -109 -23 -143 2 -13 -2 -23 -8 -23 -5 0 -10 -5 -10 -11
0 -8 6 -7 17 2 15 12 16 12 10 -3 -4 -12 -2 -18 8 -18 8 0 15 -4 15 -10 0 -17
18 -11 25 9 7 24 -9 69 -19 52 -5 -7 -3 -18 3 -24 14 -14 14 -27 1 -27 -5 0
-10 7 -10 15 0 8 -5 15 -11 15 -5 0 -7 5 -3 11 3 6 2 14 -3 18 -5 3 -4 28 3
61 6 30 12 44 13 30 0 -13 8 -44 17 -67 15 -39 20 -43 51 -43 20 0 32 4 28 10
-3 6 -1 10 4 10 6 0 11 -7 11 -15 0 -8 7 -15 15 -15 8 0 15 -4 15 -10 0 -5 7
-10 15 -10 16 0 31 -43 20 -59 -11 -19 -27 -12 -20 9 5 16 2 20 -15 20 -15 0
-20 -5 -17 -15 4 -8 2 -17 -3 -20 -6 -4 -10 -11 -10 -16 0 -6 4 -8 8 -5 5 3
15 -4 22 -15 14 -19 37 -28 26 -10 -8 13 4 42 15 35 5 -3 10 7 11 23 1 33 2
36 11 84 5 22 3 47 -4 62 -8 18 -8 30 0 43 10 16 10 15 5 -7 -3 -15 -1 -30 5
-33 7 -4 11 2 10 16 0 13 9 73 20 133 12 61 20 115 19 120 -1 6 2 17 5 25 7
18 78 446 75 457 0 5 1 15 5 23 5 14 27 137 27 154 0 3 -86 5 -191 4 l-191 -3
-39 -105 c-22 -57 -37 -110 -34 -118 2 -7 1 -11 -3 -8 -12 7 -35 -61 -26 -75
5 -8 3 -9 -6 -4 -10 6 -12 4 -7 -9 4 -9 3 -15 -1 -12 -7 4 -113 -287 -117
-323 -1 -9 -4 -23 -8 -31 -4 -8 -13 -35 -20 -60 -12 -40 -14 -11 -20 280 -4
179 -10 358 -13 398 l-7 72 -188 0 c-103 0 -190 -3 -192 -7z m524 -500 c-12
-20 -14 -14 -5 12 4 9 9 14 11 11 3 -2 0 -13 -6 -23z m-44 -135 c-3 -8 -6 -5
-6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-560 -10 c-3 -8 -6 -5 -6 6 -1 11 2
17 5 13 3 -3 4 -12 1 -19z m691 -29 c-3 -8 -7 -3 -11 10 -4 17 -3 21 5 13 5
-5 8 -16 6 -23z m-638 -14 c0 -13 -4 -27 -10 -30 -6 -4 -10 8 -10 30 0 22 4
34 10 30 6 -3 10 -17 10 -30z m131 4 c-5 -13 -10 -19 -10 -12 -1 15 10 45 15
40 3 -2 0 -15 -5 -28z m377 6 c-3 -3 -9 2 -12 12 -6 14 -5 15 5 6 7 -7 10 -15
7 -18z m449 -7 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m-870
-10 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m-140 -25 c-3 -10
-5 -2 -5 17 0 19 2 27 5 18 2 -10 2 -26 0 -35z m990 25 c-3 -8 -6 -5 -6 6 -1
11 2 17 5 13 3 -3 4 -12 1 -19z m-427 -32 c0 -6 -7 -2 -15 8 -8 11 -15 25 -15
30 0 6 7 2 15 -8 8 -11 15 -25 15 -30z m-243 2 c-3 -7 -5 -2 -5 12 0 14 2 19
5 13 2 -7 2 -19 0 -25z m480 10 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12
1 -19z m-828 -32 c-9 -8 -10 -7 -5 7 3 10 7 24 7 30 1 7 3 4 6 -7 3 -10 -1
-23 -8 -30z m198 12 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z
m-30 -25 c-3 -10 -5 -4 -5 12 0 17 2 24 5 18 2 -7 2 -21 0 -30z m408 -19 l5
-39 -13 43 c-7 25 -16 40 -21 35 -5 -5 -6 -2 -1 5 11 21 25 1 30 -44z m439 -9
c5 -22 4 -26 -4 -15 -5 8 -10 24 -10 34 0 26 6 18 14 -19z m-805 -15 c0 -10
-3 -8 -9 5 -12 27 -12 43 0 25 5 -8 9 -22 9 -30z m128 3 c-3 -10 -5 -4 -5 12
0 17 2 24 5 18 2 -7 2 -21 0 -30z m216 7 c4 -17 3 -19 -9 -9 -8 6 -14 18 -14
26 0 20 16 8 23 -17z m44 -52 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1
-19z m349 -26 c-11 -10 -15 4 -8 28 l7 25 3 -23 c2 -13 1 -26 -2 -30z m71 16
c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m-20 0 c-3 -8 -6 -5 -6
6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-373 -28 c4 -25 3 -31 -4 -20 -5 8 -10
26 -10 39 0 32 6 24 14 -19z m193 -17 c-3 -10 -5 -2 -5 17 0 19 2 27 5 18 2
-10 2 -26 0 -35z m-205 -57 c-9 -20 -10 -20 -10 4 0 14 -3 36 -6 50 -7 23 -6
23 9 -4 13 -21 14 -34 7 -50z"/>
<path d="M7805 1858 c-2 -7 -8 -44 -14 -83 -6 -38 -38 -234 -71 -435 -36 -212
-59 -380 -56 -400 4 -24 3 -29 -3 -15 -6 15 -10 3 -16 -45 -10 -75 -9 -67 -1
-104 6 -23 4 -27 -8 -23 -12 5 -16 0 -16 -17 0 -14 -2 -31 -5 -38 -5 -12 15
-15 108 -17 l114 -2 7 50 c6 42 9 49 26 46 11 -2 24 4 30 13 10 18 11 16 15
-37 2 -22 0 -29 -6 -20 -5 8 -6 22 -3 33 4 12 2 16 -5 12 -18 -11 -13 -44 9
-59 11 -8 17 -17 13 -21 -4 -4 1 -4 11 0 13 5 17 4 12 -4 -8 -13 53 -32 106
-32 44 0 91 16 84 29 -4 5 2 7 12 4 10 -2 26 5 37 18 l20 22 -22 -12 c-12 -6
-30 -11 -40 -10 -21 1 34 21 58 21 8 0 28 18 43 41 30 47 71 170 63 191 -3 7
-2 17 3 22 10 11 37 167 50 299 20 190 -14 284 -110 303 -88 18 -167 -6 -228
-69 -18 -18 -32 -28 -32 -22 0 5 11 77 25 159 14 82 27 164 30 182 l6 32 -121
0 c-88 0 -122 -3 -125 -12z m295 -487 c16 -30 12 -134 -10 -266 -25 -152 -45
-222 -70 -235 -18 -10 -104 -21 -95 -12 2 3 -1 14 -7 25 -11 21 -14 159 -4
187 3 8 10 49 16 90 24 168 58 230 124 230 25 0 38 -6 46 -19z m27 -293 c-3
-8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m130 -190 c-3 -8 -6 -5 -6 6
-1 11 2 17 5 13 3 -3 4 -12 1 -19z m-206 -21 l-24 -22 19 27 c10 14 21 24 23
22 3 -3 -6 -15 -18 -27z m-384 -9 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2
-19 0 -25z m391 -37 c9 -5 4 -8 -16 -7 -17 1 -28 4 -25 9 6 9 25 9 41 -2z
m192 -15 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10
-4z m-30 -30 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10
-2 10 -4z m-583 -68 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m39
12 c5 -5 43 -11 84 -13 l75 -3 -78 -2 c-58 -1 -82 2 -93 13 -9 8 -12 15 -7 15
5 0 13 -4 19 -10z m274 -1 c0 -6 7 -8 16 -5 9 3 25 -1 37 -9 12 -8 17 -15 12
-16 -6 0 -20 -2 -32 -3 -12 -1 -20 1 -17 5 2 4 -5 10 -16 14 -20 7 -28 25 -10
25 6 0 10 -5 10 -11z m154 -28 c18 -7 18 -8 3 -14 -9 -3 -17 -2 -17 3 0 5 -8
11 -17 13 -12 3 -13 5 -3 5 8 0 24 -3 34 -7z m-46 -18 c-10 -2 -26 -2 -35 0
-10 3 -2 5 17 5 19 0 27 -2 18 -5z"/>
<path d="M7970 706 c0 -2 7 -7 16 -10 8 -3 12 -2 9 4 -6 10 -25 14 -25 6z"/>
<path d="M9006 1643 c-21 -126 -64 -391 -97 -591 -32 -199 -59 -364 -59 -367
0 -3 51 -5 113 -5 129 0 134 3 141 90 2 25 6 52 9 60 3 8 40 227 82 485 42
259 80 489 84 513 l9 42 -123 0 -122 0 -37 -227z"/>
<path d="M4736 1713 c-6 -14 -5 -15 5 -6 7 7 10 15 7 18 -3 3 -9 -2 -12 -12z"/>
<path d="M5040 1715 c0 -8 -5 -15 -12 -15 -6 0 -9 -2 -6 -5 3 -3 18 -1 34 5
23 9 25 13 14 20 -21 14 -30 12 -30 -5z"/>
<path d="M4800 1700 c0 -5 16 -10 36 -10 21 0 33 4 29 10 -3 6 -19 10 -36 10
-16 0 -29 -4 -29 -10z"/>
<path d="M4950 1680 c0 -5 6 -10 14 -10 7 0 20 -10 29 -22 8 -13 18 -20 22
-16 5 4 -4 19 -19 33 -28 26 -46 32 -46 15z"/>
<path d="M5060 1660 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M7179 1590 c-87 -15 -195 -86 -185 -123 2 -10 2 -16 -1 -13 -14 13
-58 -58 -47 -76 4 -6 3 -8 -3 -5 -5 3 -17 -18 -26 -46 -65 -203 -86 -463 -42
-546 31 -61 124 -120 157 -100 8 5 -2 10 -30 15 -23 4 -44 10 -47 14 -2 4 7 5
20 2 14 -2 33 -1 42 3 30 13 117 9 108 -5 -8 -13 98 -13 111 0 12 12 84 20 84
9 0 -3 -19 -9 -41 -12 -23 -3 -45 -9 -48 -14 -6 -8 -10 -9 -98 -20 -55 -6 -71
6 -35 26 13 8 5 10 -33 9 -27 0 -40 -3 -27 -5 14 -3 22 -11 20 -21 -3 -15 6
-17 62 -16 36 1 79 4 95 7 90 15 177 77 219 157 24 46 25 55 3 51 -5 -1 -6 3
-2 8 3 6 13 7 21 4 13 -5 15 -1 9 17 -4 14 -3 20 3 16 11 -7 25 21 16 35 -3 5
-1 9 4 9 5 0 12 12 15 28 3 15 13 68 22 117 21 112 24 275 6 335 -15 52 -82
125 -106 116 -9 -3 -14 -2 -10 3 14 23 -141 37 -236 21z m101 -180 c9 -6 17
-5 21 2 15 24 0 -173 -22 -297 -12 -71 -23 -142 -22 -157 0 -15 -3 -25 -8 -23
-4 3 -10 -9 -14 -26 -7 -33 -57 -89 -78 -89 -7 0 -6 4 3 10 11 7 7 10 -17 10
-45 0 -65 40 -57 112 12 110 45 290 69 372 23 78 78 116 125 86z m67 -272 c-3
-7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m-250 -30 c-3 -8 -6 -5 -6 6
-1 11 2 17 5 13 3 -3 4 -12 1 -19z m-20 -80 c-3 -8 -6 -5 -6 6 -1 11 2 17 5
13 3 -3 4 -12 1 -19z m-200 -70 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12
1 -19z m170 -40 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-170
-40 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m378 1 c-7 -21 10
-27 18 -6 2 6 4 -3 3 -22 -1 -21 -7 -36 -16 -39 -13 -3 -13 -2 0 7 13 10 13
13 -2 21 -10 6 -16 22 -16 40 1 16 5 27 10 24 5 -3 6 -14 3 -25z m-194 -1 c0
-9 2 -9 6 1 4 9 13 -2 25 -28 10 -22 21 -41 24 -41 3 0 2 5 -2 12 -4 7 -3 8 5
4 9 -6 9 -11 0 -22 -17 -20 -71 13 -63 39 3 10 0 24 -6 32 -9 11 -9 15 -1 15
6 0 11 -6 12 -12z m352 -49 c-7 -24 -9 -23 -32 0 -19 19 -19 20 8 19 23 -1 27
-5 24 -19z m-182 1 c9 13 10 13 6 0 -2 -8 -13 -15 -24 -16 -15 -1 -20 3 -16
13 5 12 7 12 14 1 7 -11 11 -10 20 2z m-341 -35 c0 -8 -2 -15 -4 -15 -2 0 -6
7 -10 15 -3 8 -1 15 4 15 6 0 10 -7 10 -15z m30 -12 c0 -19 -3 -24 -10 -17 -6
6 -8 18 -4 27 9 24 14 21 14 -10z m61 -21 c36 1 46 -7 29 -27 -14 -17 -50 -21
-50 -5 0 6 -5 10 -11 10 -5 0 -7 -4 -4 -10 3 -5 2 -10 -4 -10 -15 0 -26 23
-13 28 7 2 13 18 13 35 2 30 2 30 6 5 4 -22 9 -26 34 -26z m414 36 c4 4 2 -3
-6 -15 -7 -12 -18 -19 -23 -16 -6 4 -5 8 2 11 10 3 10 7 0 20 -11 15 -11 15 3
5 9 -7 19 -9 24 -5z m-172 -80 c-2 -5 -23 -8 -46 -8 -23 0 -37 4 -30 8 15 9
82 9 76 0z"/>
<path d="M7070 843 c0 -13 5 -23 10 -23 13 0 13 11 0 30 -8 12 -10 11 -10 -7z"/>
<path d="M9630 1591 c-147 -33 -232 -124 -275 -294 -30 -120 -57 -302 -57
-397 -1 -78 20 -150 43 -150 7 0 10 -3 6 -6 -3 -4 -1 -12 6 -19 36 -35 135
-65 217 -65 86 1 206 32 194 51 -3 5 1 6 9 3 20 -7 92 60 122 114 14 24 30 63
36 86 5 22 12 49 15 59 5 15 -5 17 -108 17 l-114 0 -12 -57 c-15 -68 -43 -95
-104 -97 -29 0 -44 5 -54 19 -17 23 -19 123 -3 195 l11 50 203 0 c185 0 204 2
209 18 14 49 29 176 30 247 1 110 -27 160 -118 207 -43 22 -193 33 -256 19z
m139 -176 c16 -16 20 -93 8 -137 -7 -28 -8 -28 -92 -28 l-85 0 0 28 c0 69 32
135 75 153 26 11 76 3 94 -16z"/>
<path d="M4985 1580 c-3 -5 -1 -10 6 -10 8 0 8 -4 -1 -15 -11 -13 -15 -13 -23
-3 -6 10 -7 8 -3 -4 3 -11 -2 -19 -14 -23 -11 -3 -19 -9 -19 -13 3 -19 -2 -26
-10 -13 -6 9 -11 10 -15 2 -4 -6 -2 -11 5 -11 8 0 8 -4 -1 -15 -11 -13 -15
-13 -22 -3 -7 10 -9 10 -5 -2 2 -8 0 -23 -5 -32 -5 -10 -7 -18 -6 -18 2 0 36
33 75 74 59 60 70 76 59 85 -10 8 -16 9 -21 1z"/>
<path d="M8536 1343 c-22 -131 -40 -251 -41 -268 -2 -16 -5 -37 -8 -45 -5 -14
-57 -326 -57 -342 0 -5 53 -8 118 -8 l119 0 21 123 c12 67 45 262 72 432 28
171 52 318 54 328 4 15 -7 17 -118 17 l-121 0 -39 -237z m-13 -634 l42 -2 -50
-7 c-27 -4 -52 -6 -54 -4 -2 2 0 4 6 4 6 0 10 8 8 18 -1 9 0 11 2 5 3 -7 23
-14 46 -14z"/>
<path d="M4797 1533 c-12 -11 -8 -23 7 -23 7 0 20 -10 29 -22 8 -13 19 -19 23
-14 5 4 -1 15 -13 23 -13 9 -23 22 -23 29 0 15 -12 19 -23 7z"/>
<path d="M4802 1412 c-2 -21 1 -31 8 -27 22 14 8 -10 -25 -42 -19 -19 -40 -31
-45 -28 -15 9 -12 25 3 19 7 -2 21 3 30 13 15 17 14 18 -16 19 -31 0 -32 -1
-34 -44 -2 -41 -7 -50 -75 -117 -50 -51 -76 -70 -81 -62 -6 8 -7 6 -3 -5 6
-19 -49 -72 -65 -62 -14 8 -11 24 4 18 7 -2 22 6 33 18 l19 23 -23 -19 c-12
-11 -25 -16 -29 -12 -4 4 -1 11 7 16 11 7 10 9 -7 8 -14 -2 -19 -7 -15 -17 3
-9 1 -12 -6 -7 -7 4 -12 3 -12 -3 0 -5 5 -13 10 -16 6 -4 7 -11 4 -17 -4 -7
-2 -8 4 -4 21 12 13 -10 -10 -30 -21 -19 -21 -19 -14 0 7 19 6 19 -14 1 -13
-11 -16 -21 -10 -25 6 -4 2 -14 -11 -26 -22 -19 -22 -19 -15 0 5 15 1 13 -18
-7 -28 -31 -30 -31 -58 -16 -13 7 -18 7 -14 1 3 -6 1 -14 -5 -18 -17 -10 12
-25 36 -17 18 5 18 4 6 -11 -8 -9 -20 -15 -28 -12 -8 3 -11 1 -8 -4 3 -6 -1
-13 -9 -16 -9 -3 -13 -10 -10 -15 8 -14 73 51 67 67 -3 8 0 14 6 14 6 0 11 -4
11 -9 0 -7 414 405 470 466 3 3 -5 1 -16 -5 -21 -11 -34 -7 -34 9 0 5 6 6 13
3 7 -2 21 3 30 13 15 17 14 18 -17 19 -30 0 -32 -2 -34 -34z m38 18 c0 -5 -4
-10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m-80 -80 c0
-5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m-410
-410 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z"/>
<path d="M4637 1373 c-12 -11 -8 -23 7 -23 7 0 20 -10 29 -22 8 -13 19 -19 23
-14 5 4 -1 15 -13 23 -13 9 -23 22 -23 29 0 15 -12 19 -23 7z"/>
<path d="M4648 1272 c3 -10 1 -13 -6 -8 -8 5 -10 1 -5 -10 4 -10 8 -13 10 -7
3 7 10 10 17 7 7 -3 20 3 29 13 15 17 14 18 -18 19 -25 0 -32 -3 -27 -14z m32
-2 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z"/>
<path d="M4485 1200 c3 -5 13 -10 22 -10 10 0 14 -6 10 -19 -6 -24 19 -36 27
-14 4 8 -3 23 -16 34 -24 19 -53 26 -43 9z"/>
<path d="M4564 1190 c0 -24 31 -22 53 3 l18 22 -23 -19 c-12 -11 -25 -16 -29
-12 -4 4 -1 11 7 16 12 7 10 9 -5 8 -12 -1 -21 -9 -21 -18z"/>
<path d="M4404 1028 c0 -16 2 -16 18 -1 10 9 18 17 18 19 0 1 -8 2 -18 1 -10
0 -18 -9 -18 -19z"/>
<path d="M4357 1010 c-4 -15 0 -20 15 -20 13 0 18 6 16 17 -5 24 -25 27 -31 3z"/>
<path d="M5982 960 c1 -39 4 -73 8 -75 9 -5 5 134 -3 142 -3 2 -5 -28 -5 -67z"/>
<path d="M5662 990 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M5660 946 c0 -8 -7 -16 -15 -20 -8 -3 -15 -13 -15 -23 0 -14 6 -11
25 12 26 33 31 45 15 45 -5 0 -10 -6 -10 -14z"/>
<path d="M6441 923 c-1 -12 4 -25 9 -28 12 -7 12 2 0 30 -8 19 -9 19 -9 -2z"/>
<path d="M4047 889 c7 -7 15 -10 18 -7 3 3 -2 9 -12 12 -14 6 -15 5 -6 -5z"/>
<path d="M4086 885 c-4 -12 3 -15 34 -15 34 0 40 -3 40 -21 0 -14 5 -19 17
-17 24 4 28 27 6 35 -10 3 -22 12 -26 20 -11 18 -63 17 -71 -2z"/>
<path d="M4231 876 c21 -16 17 -35 -11 -56 -14 -11 -20 -20 -13 -20 6 0 18 7
27 15 8 8 20 15 27 15 9 0 8 4 -1 15 -14 17 0 21 17 4 8 -8 13 -7 18 2 5 7 4
10 -3 5 -6 -3 -13 -2 -17 4 -3 5 -1 10 6 10 26 0 -3 13 -32 15 -25 2 -29 0
-18 -9z"/>
<path d="M6070 875 c0 -9 6 -12 15 -9 8 4 15 10 15 15 0 5 -7 9 -15 9 -8 0
-15 -7 -15 -15z"/>
<path d="M6185 880 c-7 -12 -7 -12 23 -15 13 -1 22 3 22 11 0 16 -36 19 -45 4z"/>
<path d="M5591 854 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M5982 835 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"/>
<path d="M6240 845 c0 -8 2 -15 4 -15 2 0 6 7 10 15 3 8 1 15 -4 15 -6 0 -10
-7 -10 -15z"/>
<path d="M5582 810 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M5797 800 c8 -47 19 -57 27 -25 4 15 5 30 1 33 -3 3 -5 -3 -5 -14 -1
-14 -5 -11 -15 11 l-14 30 6 -35z"/>
<path d="M6222 791 c-17 -48 -14 -63 4 -22 8 19 13 39 11 45 -2 6 -9 -5 -15
-23z"/>
<path d="M6397 813 c-10 -9 -9 -23 2 -23 5 0 11 7 15 15 5 15 -5 20 -17 8z"/>
<path d="M4120 801 c-25 -7 -25 -8 -3 -15 22 -7 78 2 68 12 -9 9 -38 10 -65 3z"/>
<path d="M5772 790 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M6166 795 c-9 -26 -7 -32 5 -12 6 10 9 21 6 23 -2 3 -7 -2 -11 -11z"/>
<path d="M6201 794 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M3720 770 l-25 -8 25 -2 c14 -2 32 -3 40 -4 8 -1 20 -4 25 -7 6 -4
37 -7 70 -7 51 0 55 1 30 9 -16 5 -38 6 -47 3 -10 -3 -18 -3 -18 0 0 3 15 10
33 16 30 9 30 9 2 4 -41 -8 -77 -9 -95 -2 -8 3 -26 2 -40 -2z"/>
<path d="M5570 756 c0 -8 4 -17 9 -20 5 -4 7 3 4 14 -6 23 -13 26 -13 6z"/>
<path d="M6186 750 c3 -14 10 -29 15 -34 14 -16 10 6 -7 34 l-15 25 7 -25z"/>
<path d="M6386 753 c-6 -14 -5 -15 5 -6 7 7 10 15 7 18 -3 3 -9 -2 -12 -12z"/>
<path d="M5994 707 c1 -21 6 -22 82 -25 80 -3 81 -2 92 25 11 24 11 26 -1 11
-11 -14 -30 -18 -91 -18 -45 0 -75 4 -71 9 3 5 1 12 -3 15 -5 3 -9 -5 -8 -17z"/>
<path d="M6397 718 c2 -5 40 -8 83 -8 43 0 81 3 83 8 3 4 -35 7 -83 7 -48 0
-86 -3 -83 -7z"/>
<path d="M6577 710 c-3 -13 -4 -26 -2 -29 3 -2 5 0 5 6 0 6 11 8 25 5 15 -2
25 0 25 7 0 6 -9 11 -19 11 -11 0 -21 6 -23 12 -3 7 -7 2 -11 -12z"/>
<path d="M5562 698 c2 -6 11 -12 20 -14 11 -1 15 3 11 12 -6 17 -36 19 -31 2z"/>
<path d="M5780 700 c12 -8 10 -10 -10 -11 -14 0 -19 -3 -12 -6 16 -6 62 5 62
15 0 4 -12 9 -27 10 -22 1 -24 0 -13 -8z"/>
<path d="M6177 696 c-4 -10 -1 -13 8 -9 8 3 12 9 9 14 -7 12 -11 11 -17 -5z"/>
<path d="M6376 695 c5 -12 25 -15 95 -15 57 0 89 4 89 11 0 7 -29 9 -85 7 -47
-2 -85 0 -85 5 0 4 -4 7 -10 7 -5 0 -7 -7 -4 -15z"/>
<path d="M7103 683 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
</g>
</svg>









    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
